.home {
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // overflow: hidden;
  background-color: #fff;
  .container {
    height: 94vh;
    display: flex;
    flex-direction: column;
    > * {
      flex: none;
    }
  }

  .back_icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .back_img {
    width: 35px;
    height: 35px;
  }

  :global {
    .adm-list-item {
      width: 100%;
    }
    .adm-list-item-content {
      border-top: none;
    }
    .adm-list {
      --border-top: none;
      --border-bottom: none;
      --padding-left: 0px;
      --padding-right: 0px;
    }
    .adm-list-item-content-main {
      padding: 0;
    }
    .adm-input-element {
      padding: 7px 0;
      border-radius: 10px;
    }
    .adm-list-item {
      border-radius: 4%;
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .adm-text-area-element {
      // height: auto !important;
      // max-height: 8.25rem;
      // min-height: 1.5rem;
    }
  }

  .input_location {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .input_element {
    display: flex;
    align-items: center;
    // height: 80px;
    padding: 5px 5px;
    // padding-top: 5px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    background: #f1f1f1;
  }

  .user_image {
    background: #f5f7fa;
    width: 240px;
    // padding: 3px;
    border-radius: 5px;
    height: auto;
    box-shadow: 0px 3px 4px 0px rgba(3, 4, 5, 0.07);
  }
  .user_content {
    display: flex;
    margin: 10px;
    .user_head {
      background: url("../../assets/ic_chatgpt_active.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
  }
  .user_desc {
    width: auto;
    max-width: 85%;
    display: flex;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
    background: #fef9eb;
  }
  .user_message {
    // display: flex;
    width: auto;
    max-width: 85%;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    background: #f5f7fa;
    white-space: pre-wrap;

    // animation: typing 3.5s steps(30, end), blink 0.75s step-end infinite;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%!;
    }
  }

  .user_message_show::after {
    content: "";
    width: 1ch;
    height: 2ch;
    display: inline-block;
    vertical-align: text-top;
    background-color: #222;
    animation: svelteCursorFade 1.25s infinite;
  }
  @keyframes svelteCursorFade {
    0%,
    to {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
  }
  .chat_content {
    display: flex;
    flex-flow: row-reverse;
    margin: 10px;

    .chat_head {
      background: url("../../assets/head_user.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 40px;
      height: 40px;
      margin-left: 5px;
    }
  }
}
